import * as React from "react";
import { createBrowserHistory } from "history";
import { ChildrenProps } from "../propTypes";
import { Router } from "react-router-dom";
import ReactGA from "react-ga";

type Props = {}
type AllProps = Props & ChildrenProps;

const AnalyticsRouter = (props: AllProps) => {
  const browserHistory = createBrowserHistory();
  const { children } = props;

  const track = (path: string) => {
    ReactGA.set({ page: path })
    ReactGA.pageview(path)
  }

  browserHistory.listen(location => {
    track(location.pathname);
  });

  track(window.location.pathname);
  return <Router history={browserHistory}>
    {children}
  </Router>
}

export default AnalyticsRouter;