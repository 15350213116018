import * as React from "react";
import { PageHeader, Divider } from "antd";
import { Link } from "react-router-dom";
import { Path } from "../../Path";
import WebmasterEmail from "../../components/WebmasterEmail";

type AllProps = {}
const DatenschutzPage = (props: AllProps) => {
  const host = window.location.host;

  return (<>
    <PageHeader title="Datenschutz" />
    <p>Nachfolgend möchten wir Sie darüber informieren, welche personenbezogenen Daten wir
      im Rahmen der Nutzung unserer Internetseite {host} verarbeiten und zu welchem Zweck
      diese Verarbeitung erfolgt und welche Rechte Ihnen als betroffene Person nach
      dem Datenschutzrecht zustehen.</p>
    <Divider />
    <h3>Verantwortlicher nach Art. 4 Nr. 7 DSGVO (Datenschutzgrundverordnung)</h3>
    <p>Verantwortlicher im Sinne der DSGVO ist: Florian Ibach</p>
    <p>Sie können uns bei Fragen zum Datenschutz gerne über E-Mail unter <WebmasterEmail /> erreichen.</p>
    <Divider />
    <h3>Personenbezogene Daten, Grundsatz der Rechtmäßigkeit und Zweckbindung</h3>
    <p>Gemäß Art. 4 Nr. 1 DSGVO sind „personenbezogene Daten“ alle Informationen,
      die sich auf eine identifizierte oder identifizierbare natürliche Person (im
      Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche
      Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer
      Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
      oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die
      Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen,
      kulturellen oder sozialen Identität dieser natürlichen Person sind.
    </p>
    <p>Bei Nutzung des Internets findet über Netzwerke und bestimmte Protokolle eine
      Kommunikation zwischen Netzwerkgeräten statt. Unsere Internetseite wird über den
      Server eines Internetdienstleisters betrieben. Diese Informationen sind über eine
      bestimmte Internetadresse erreichbar. Die Möglichkeiten, unsere Seite aufzurufen,
      sind vielfältig. In der Regel erfolgt die Verbindung des eigenen Netzwerkes über
      einen Internetrouter. Dieser wählt sich über die Zugangsdaten des Internetproviders
      ins Internet ein. Möglich und häufig ist auch die Einwahl direkt über ein internetfähiges
      Endgerät, das sich über Mobilfunk direkt über den Netzanbieter mit dem Internet verbindet.
      Insbesondere bei der Nutzung öffentlicher Netzwerke sollten Sie sich immer der Tatsache
      bewusst sein, dass die übertragenen Daten von Dritten einsehbar sind und es Dritten technisch
      möglich ist, die Inhalte auszulesen.
    </p>
    <p>Für diese Kommunikation über das Internet muss jedem Gerät eine bestimmte Netzwerkadresse
      zugeordnet werden. Diese sogenannte IP-Adresse ist als personenbezogenes Datum einzuordnen,
      denn über eine (unter bestimmten gesetzlichen Voraussetzungen mögliche) Anfrage beim jeweiligen
      Provider lässt sich z.B. der Inhaber des jeweiligen Internetanschlusses ermitteln.
    </p>
    <p>Eine "Verarbeitung" ist gemäß Art. 4 Nr.2 DSGVO jeder mit oder ohne Hilfe automatisierter
      Verfahren ausgeführten Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen
      Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung
      oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung,
      Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die
      Einschränkung, das Löschen oder die Vernichtung.
    </p>
    <p>Nach dem Grundsatz der Rechtmäßigkeit nach Art. 5 Abs. 1 Buchstabe a DSGVO müssen personenbezogene
      Daten "auf rechtmäßige Weise" verarbeitet werden. Eine Verarbeitung setzt daher in jedem Einzelfall
      eine gesetzliche Rechtfertigung voraus. Nach dem Grundsatz der Zweckbindung (Art. 5 Abs. 1 Buchstabe b
      DSGVO) müssen personenbezogene Daten "für festgelegte, eindeutige und legitime Zwecke erhoben werden
      und dürfen nicht mit einer mit diesen Zwecken nicht zu vereinbarenden Weise verarbeitet werden".
    </p>
    <Divider />
    <h3>Verarbeitung von personenbezogenen Daten bei Besuch unserer Website</h3>
    <p>Der Aufruf unserer Website führt technisch bei jedem Seitenaufruf zu einem Datenaustausch des von Ihnen
      genutzten Rechners oder Gerätes und des Servers, auf dem die Daten unserer Website gespeichert sind.
      Dies erfolgt allein durch Aufruf unserer Internetseite {host} und der jeweiligen Unterseiten im Rahmen
      der Kommunikation über das Internet. Das verwendete Protokoll und die Kommunikation im Internet führt
      zu einem Datenaustausch und einer Protokollierung bestimmter Tatsachen, die vom Server protokolliert
      werden. Dies sind folgende Daten:
    </p>
    <ul>
      <li>das Datum und die Uhrzeit jedes Aufrufes unserer Internetseite und der Unterseiten;</li>
      <li>die IP-Adresse des Rechners oder Gerätes, mit dem Sie unsere Seite aufrufen;</li>
      <li>den Namen und die Internetadresse („URL“) der aufgerufenen Datei;</li>
      <li>der Name des von Ihnen verwendeten Browsers und des Betriebssystems;</li>
      <li>die Internetadresse der zuletzt von Ihnen besuchten Internetseite.</li>
    </ul>
    <p>Je nach gewählter Einstellung Ihres Browsers können Sie die übertragenen Daten beschränken.
      Viele Browser bieten eine Anonymisierung an. Dies führt dazu, dass z.B. der Name des von Ihnen
      verwendeten Browser nicht übermittelt wird. Beachten Sie aber bitte, dass dies auf die Übermittlung
      Ihrer IP-Adresse keine Auswirkungen hat. Eine weitere Einschränkung der Datenübermittlung lässt sich
      durch Anonymisierungstools oder ggf. durch entsprechende Webseiten erreichen. Über die Nutzung
      bestimmter Anonymisierungsdiente (z.B. <a href="https://www.torproject.org/">Tor</a>) lässt sich die
      Übertragung der IP-Adresse ebenfalls grundsätzlich unterbinden. Eine völlige Anonymität lässt sich
      bei einer Nutzung des Internets wohl kaum erreichen.
    </p>
    <Divider />
    <h3>Zweck der Datenverarbeitung und Grundlage der Rechtsmäßigkeit der Verarbeitung</h3>
    <p>Der Zweck der Datenverarbeitung ist der Betrieb der von uns betriebenen Internetwebsite {host}.
    Die Datenverarbeitung dient der Sicherung des technischen Ablaufs der Kommunikation und Verbindung
    zwischen Ihrem Rechner/Gerät und dem von uns genutzten Server. Technisch nutzen wir die Daten auch
    zur Auswertung der Systemsicherheit und -stabilität sowie zu administrativen Zwecken, die mit dem
    Betrieb und der Wartung dieses Dienstes zusammenhängen.
    </p>
    <p>Das Gesetz erlaubt in bestimmten Fällen die Datenverarbeitung ohne Einwilligung. Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 Buchstabe f DSGVO. Unser berechtigtes Interesse folgt aus dem Interesse eines Unternehmens eine Website zu betreiben und die angebotenen Dienstleistungen im Internet zu präsentieren und dem Zwecke der Werbung für unsere Dienstleistungen.
    </p>
    <p>Eine rechtmäßige Verarbeitung von Daten kann in folgenden Fällen erfolgen:</p>
    <ul>
      <li>Die Verarbeitung ist für die Erfüllung einer rechtlichen Verpflichtung erforderlich, der der Verantwortliche unterliegt (Art. 6 Abs. 1 S. 1 Buchstabe c DSGVO)</li>
      <li>Sie erteilen Ihre ausdrückliche Einwilligung hierzu (Art. 6 Abs. 1 S. 1 Buchstabe a DSGVO)</li>
      <li>die Verarbeitung ist zur Wahrung der berechtigten Interessen von uns oder eines Dritten erforderlich, sofern nicht Ihre Interessen oder Grundrechte und Grundfreiheiten, die den Schutz personenbezogener Daten erfordern, überwiegen (Art. 6 Abs. 1 S. 1 Buchstabe f)</li>
      <li>für den Fall, dass die Weitergabe nach Art. 6 Abs. 1 S. 1 Buchstabe b DSGVO für die Erfüllung eines Vertrages, dessen Vertragspartei Sie sind oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist, die auf Anfrage von Ihnen erfolgt sind.</li>
      <li>Es liegt ein sonstiger gesetzlicher Erlaubnistatbestand vor. Mitgliedsstaaten haben in bestimmten Fällen die Möglichkeit, weitere Erlaubnistatbestände zu erlassen.</li>
    </ul>
    <Divider />
    <h3>Weitergabe von Daten</h3>
    <p>Eine Weitergabe von Daten durch uns an Dritte findet nicht statt.</p>
    <Divider />
    <h3>Cookies</h3>
    <p>Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Router und/oder Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.</p>
    <p>In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten.</p>
    <p>Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits besucht haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.</p>
    <p>Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.</p>
    <p>Zum anderen setzten wir ggf. Cookies ein, um die Nutzung unserer Website statistisch zu erfassen. Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht.</p>
    <p>Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen an der Optimierung unserer Website für die Nutzer nach Art. 6 Abs. 1 S. 1 Buchstabe f DSGVO zulässig und erforderlich.</p>
    <p>Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen unserer Website nutzen können.</p>
    <Divider />
    <h3>Analyse-Tools</h3>
    <h4>Google – Analytics (Google-Maps Plugin)</h4>
    <p>Diese Website nutzt den Dienst "Google Analytics", welcher von der Google Inc.
      (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) angeboten wird, zur
      Analyse der Website – benutzung durch Nutzer. Der Dienst verwendet "Cookies"–
      Textdateien, welche auf Ihrem Endgerät gespeichert werden. Die durch die
      Cookies gesammelten Informationen werden im Regelfall an einen Google-Server
      in den USA gesandt und dort gespeichert. Auf dieser Website greift die IP-
      Anonymisierung. Die IP – Adresse der Nutzer wird innerhalb der Mitgliedsstaaten
      der EU und des Europäischen Wirtschaftsraum gekürzt. Durch diese Kürzung entfällt
      der Personenbezug Ihrer IP – Adresse. Im Rahmen der Vereinbarung zur
      Auftragsdatenvereinbarung, welche die Websitebetreiber mit der Google Inc.
      geschlossen haben, erstellt diese mithilfe der gesammelten Informationen eine
      Auswertung der Websitenutzung und der Website aktivität und erbringt mit der
      Internetnutzung verbundene Dienstleistungen. Sie haben die Möglichkeit, die
      Speicherung des Cookies auf Ihrem Gerät zu verhindern, indem Sie in Ihrem Browser
      entsprechende Einstellungen vornehmen. Es ist nicht gewährleistet, dass Sie auf
      alle Funktionen dieser Website ohne Einschränkungen zugreifen können, wenn Ihr
      Browser keine Cookies zulässt. Weiterhin können Sie durch ein Browser – Plugin verhindern,
      dass die durch Cookies gesammelten Informationen (inklusive Ihrer IP – Adresse)
      an die Google Inc. gesendet und von der Google Inc. genutzt werden. Folgender Link
      führt Sie zu dem entsprechenden Plugin: <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
        https://tools.google.com/dlpage/gaoptout?hl=de</a>. Mit dem Klick auf obigen Link laden
Sie ein „Opt-Out – Cookie“ herunter. Ihr Browser Muster von datenschutz.org muss die
Speicherung von Cookies also hierzu grundsätzlich erlauben. Löschen Sie Ihre Cookies regelmäßig,
ist ein erneuter Klick auf den Link bei jedem Besuch dieser Website vonnöten.
Hier finden Sie weitere Informationen zur Datennutzung durch die Google Inc.:
      <a href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com</a>
    </p>
    <Divider />
    <h3>Eingebettete Inhalte von anderen Websites</h3>
    <p>Beiträge auf dieser Website können eingebettete Inhalte beinhalten (z. B. Videos, Bilder,
      Beiträge etc.). Eingebettete Inhalte von anderen Websites verhalten sich exakt so, als ob
      der Besucher die andere Website besucht hätte.
    </p>
    <p>Diese Websites können Daten über Sie sammeln, Cookies benutzen, zusätzliche Tracking-Dienste
      von Dritten einbetten und deine Interaktion mit diesem eingebetteten Inhalt aufzeichnen,
      inklusive deiner Interaktion mit dem eingebetteten Inhalt, falls du ein Konto hast und
      auf dieser Website angemeldet bist.
    </p>
    <Divider />
    <h3>Betroffenenrechte</h3>
    <p>Sie haben als Betroffener nach dem Datenschutzrecht folgende Betroffenenrechte:</p>
    <ul>
      <li>
        gemäß Art. 15 DSGVO können Sie Auskunft über Ihre von uns verarbeiteten personenbezogenen
        Daten verlangen. Dies umfasst insbesondere die Auskunft über die Verarbeitungszwecke, die
        Kategorien der personenbezogenen Daten, die Empfänger oder Kategorien von Empfängern,
        gegenüber denen Ihre Daten offengelegt worden sind oder offengelegt werden, falls möglich
        die geplante Speicherdauer der personenbezogenen Daten, das Bestehen eines Rechts auf Berichtigung,
        Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts,
        die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer
        automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen
        zu deren Einzelheiten
      </li>
      <li>
        gemäß Art. 16 DSGVO können Sie von uns unverzüglich die Berichtigung unrichtiger oder die
        Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten verlangen
      </li>
      <li>
        gemäß Art. 17 DSGVO können Sie die Löschung Ihrer bei uns gespeicherten personenbezogenen
        Daten verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung
        und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen
        Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich
        ist ("Recht auf Löschung")
      </li>
      <li>
        gemäß Art. 18 DSGVO können Sie die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
        verlangen ("Recht auf Einschränkung der Verarbeitung"), wenn
      </li>
      <li>
        die Richtigkeit der Daten von Ihnen bestritten wird, und zwar für eine Dauer, die es uns ermöglicht,
        die Richtigkeit der personenbezogenen Daten zu überprüfen
      </li>
      <li>
        wenn die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und Sie stattdessen die
        Einschränkung der Nutzung der personenbezogenen Daten verlangen
      </li>
      <li>wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen</li>
      <li>
        oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben, solange nicht feststeht, ob die berechtigten Gründe
        von uns gegenüber der betroffenen Person überwiegen
      </li>
      <li>
        gemäß Art. 20 DSGVO haben Sie das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben,
        in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten. Sie haben zudem das Recht,
        diese Daten einem anderen Verantwortlichen zu übermitteln ohne Behinderung durch uns (dem Verantwortlichen,
        dem die personenbezogenen Daten bereitgestellt wurden), sofern die Verarbeitung (a) auf einer Einwilligung
        gemäß Art. 6 Abs. 1 Buchst. a oder Artikel 9 Absatz 2 Buchstabe a oder auf einem Vertrag gemäß Art. 6 Abs.
        1 Buchstabe b DSGVO beruht und (b) die Verarbeitung mithilfe automatisierter Verfahren erfolgt ("Recht auf
        Datenübertragbarkeit").
      </li>
      <li>
        gemäß Art. 7 Abs. 3 DSGVO können Ihre einmal erteilte Einwilligung jederzeit gegenüber uns mit Wirkung für die
        Zukunft widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung
        bis zum Widerruf erfolgten Verarbeitung nicht berührt.
      </li>
      <li>
        gemäß Art. 77 DSGVO können Sie sich unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
        Rechtsbehelfs bei einer Aufsichtsbehörde beschweren, insbesondere in dem Mitgliedsstaat Ihres gewöhnlichen
        Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes (der Sitz unseres Vereins),
        wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen diese
        Verordnung verstößt.
      </li>
    </ul>
    <Divider />
    <h3>Widerspruchsrecht</h3>
    <p>Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 Buchst.
      f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO jederzeit Widerspruch gegen die Verarbeitung
      der Sie betreffenden personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer
      besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein
      generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird. Möchten Sie von
      Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an <WebmasterEmail />. Selbstverständlich
      können Sie uns den Widerspruch auch formlos, schriftlich oder in Textform mitteilen.
    </p>
    <Divider />
    <h3>Datensicherheit</h3>
    <p>Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit
      der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich
      dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir
      stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt
      übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der
      unteren Statusleiste Ihres Browsers.
    </p>
    <p>Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten
      gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen
      den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen
      Entwicklung fortlaufend verbessert.
    </p>
    <Divider />
    <h3>Stand dieser Datenschutzerklärung</h3>
    <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand 25.Mai 2018.</p>
    <p>Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise
      behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern.
      Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website <Link to={Path.DATENSCHUTZ}>hier</Link> von
      Ihnen abgerufen und ausgedruckt werden.
    </p>
  </>)
}

export default DatenschutzPage;