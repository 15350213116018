import * as React from "react";
import {ChildrenProps} from "../../../framework-candidates/propTypes";
import Team from "../../../components/Team/Team";
import TeamResults from "../../../components/Team/TeamResults";
import logo from "../../../imgs/aktive/2022_23_gesamt.jpg";
import logoMin from "../../../imgs/aktive/2022_23_gesamt_min.jpg";

//TODO (fi): hier in general verschieben
type TeamProps = {};
type AllProps = ChildrenProps;

const Team1Page: React.FC<AllProps> = props => {
  const config = Config.herren;

  return <>
    <Team
      title={"Herren"}
      trainer={config.trainer}
      spielklasse={config.spielklasse}
      spielausschuss={config.spielausschuss?.join(", ")}
      trainingstermine={config.trainingstermine}
      logo={logo}
      logoMin={logoMin}
      kader={config.kader}
    />
    <TeamResults tabs={
      [{tabDescription: "Tabelle Herren I", widgetKey: config.fussballDe.tabelle},
        {tabDescription: "Ergebnisse Herren I", widgetKey: config.fussballDe.ergebnisse},
        {tabDescription: "Tabelle Herren II", widgetKey: config.fussballDeTeam2.tabelle},
        {tabDescription: "Ergebnisse Herren II", widgetKey: config.fussballDeTeam2.ergebnisse}]}/>
  </>
}

export default Team1Page;