import * as React from "react";

type Props = {};
type AllProps = Props;

const StadiumMaps = (props: AllProps) => {
  return (
    <div style={{ height: "40%", minHeight: "350px" }}>
      <iframe src={Config.google.mapsUri}
        width="100%"
        height="350px"
        title="Google Maps"
      ></iframe>
    </div>
  );
}

export default StadiumMaps;