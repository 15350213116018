import * as React from "react";
import Email from "../framework-candidates/common/Email";

type Props = {}
type AllProps = Props;

const WebmasterEmail = (props: AllProps) => {
  return <Email mail={Config.webmasterMail} />
}

export default WebmasterEmail;