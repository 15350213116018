import * as React from "react";
import {Card, Divider, PageHeader, Row} from "antd";
import {sponsors_all} from "../imgs";
import LightBoxImage from "../framework-candidates/images/LightBoxImage";
import ResponsiveCol from "../components/layout/ResponsiveCol";
import styles from "./VorstandschaftPage.module.css";
import Email from "../framework-candidates/common/Email";

import goldSponsors from "../types/Sponsors/GoldSponsors";
import Sponsor from "../types/Sponsors/Sponsor";
import silverSponsors from "../types/Sponsors/SilverSponsors";
import platinSponsors from "../types/Sponsors/PlatinSponsors";
import bronzeSponsors from "../types/Sponsors/BronzeSponsors";
import otherSponsors from "../types/Sponsors/OtherSponsors";

const SponsorsPage = () => {

  const allSponsors = platinSponsors
    .concat(goldSponsors)
    .concat(silverSponsors)
    .concat(bronzeSponsors)
    .concat(otherSponsors);

  const mapSponsorsNew = () => {
    return allSponsors.map((v: Sponsor, i: number) => {
      return (<ResponsiveCol count={3} key={i} className={styles.guttered}>
          <a href={v.Homepage} target="_blank" rel="noopener noreferrer" title={v.Name}>
            <Card
              style={{padding: "20px"}}
              className={`sponsorsCard`}
              cover={<img src={v.Img} alt={v.Name}/>}>
            </Card>
          </a>
        </ResponsiveCol>
      )
    });
  }

  return (
    <>
      <PageHeader title="Unsere Partner"/>
      <p>
        Wir bedanken uns herzlichst bei allen unseren Partnern. <br/>
        Nur durch euch ist der Spielbetrieb beim TuS Hügelsheim möglich.
      </p>
      <Divider/>
      <p>
        Sie haben Interesse an einem Sponsoring und Teil unseres Teams zu werden? <br/>
        Hier haben Sie die Gelegenheit, Ihr Unternehmen in der Region als Unterstützer
        und Förderer von Jugendlichen und Erwachsenen zu präsentieren <br/>
      </p>
      <p>
        Senden Sie uns eine Mail an: <Email mail="sponsoring"/>
      </p>
      <Divider/>
      <Row type="flex">
        {mapSponsorsNew()}
      </Row>
    </>
  )
}

export default SponsorsPage;