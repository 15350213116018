import * as React from "react";
import {Card, Divider, PageHeader, Row} from "antd";
import Meta from "antd/lib/card/Meta";
import {AppColors} from "../AppColors";
import ResponsiveCol from "../components/layout/ResponsiveCol";
import styles from "./VorstandschaftPage.module.css";
import useIsMobile from "../framework-candidates/hooks/useIsMobile";
import {randomKey} from "../framework-candidates/util/RandomKeyGen";
import logoPlaceholder from "../imgs/vorstand/placeholder.jpg";
import logoDKoepplin from "../imgs/vorstand/Daniel_Koepplin.jpeg";
import logoDRoscic from "../imgs/vorstand/Damir_Roscic.jpg";
import logoFIbach from "../imgs/vorstand/Florian_Ibach.jpg";
import logoADaines from "../imgs/vorstand/Alexander_Daines.jpeg";
import logoDRekic from "../imgs/vorstand/Dennis_Rekic.jpeg";
import logoJIbach from "../imgs/vorstand/Johannes_Ibach.jpeg";
import logoMSchaefer from "../imgs/vorstand/Marco_Schäfer.jpg";
import logoRLindt from "../imgs/vorstand/Roland_Lindt.jpeg";

type AllProps = {}
const VorstandschaftPage: React.FC<AllProps> = props => {
  const isMobile = useIsMobile();

  const renderTitle = isMobile ? "Vorstandschaft - Abteilung Fussball" : "Die Vorstandschaft des TuS Hügelsheim Abteilung Fussball";

  // TODO (fi): move to own component!
  const cardCover = (src?: string) => {
    return <img src={src ? src : logoPlaceholder} alt="" key={randomKey()}/>
  }

  const outerCard = (title: string, name: string, src?: string, seit?: number) => {
    return (
      <Card
        title={title}
        style={{borderColor: AppColors.BLACK, display: "grid", gridTemplateRows: "1fr auto auto", height: "100%"}}
        cover={cardCover(src)}
      >
        <Meta title={name} description={seit ? `seit ${seit}` : "-"}/>
      </Card>
    )
  }

  return (<>
    <PageHeader
      title={renderTitle}
      backIcon={false}
    />
    <h3 className={styles.guttered}>Vereinsvorstand:</h3>
    <Row type="flex">
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("Abteilungsvorstand", "Damir Roscic (komm.)", logoDRoscic, 2021)}
      </ResponsiveCol>
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("Kassier", "Marco Schäfer", logoMSchaefer, 2014)}
      </ResponsiveCol>
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("Fest- und Werbekoordinator", "Florian Ibach", logoFIbach, 2014)}
      </ResponsiveCol>
    </Row>
    <Row type="flex">
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("Jugendleiter", "Damir Roscic", logoDRoscic, 2016)}
      </ResponsiveCol>
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("Schriftführer", "Marco Rieger", logoPlaceholder, 2023)}
      </ResponsiveCol>
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("Sportlicher Leiter", "Eduard Tarabrin", logoPlaceholder, 2021)}
      </ResponsiveCol>
    </Row>
    <Row type="flex">
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("Sportlicher Leiter", "Bayram Ercan", logoPlaceholder, 2021)}
      </ResponsiveCol>
    </Row>

    <Divider/>
    <h3 className={styles.guttered}>Sponsoring Abteilung:</h3>
    <Row type="flex">
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("", "Johannes Ibach", logoJIbach, 2022)}
      </ResponsiveCol>
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("", "Dennis Rekic", logoDRekic, 2022)}
      </ResponsiveCol>
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("", "Marco Rieger", logoPlaceholder, 2022)}
      </ResponsiveCol>
    </Row>

    <Divider/>
    <h3 className={styles.guttered}>Festausschuss:</h3>
    <Row type="flex">
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("", "Daniel Köpplin", logoDKoepplin, 2022)}
      </ResponsiveCol>
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("", "Alexander Daines", logoADaines, 2022)}
      </ResponsiveCol>
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("", "Stefan Bauer", logoPlaceholder, 2022)}
      </ResponsiveCol>
    </Row>
    <Row type="flex">
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("", "Wadim Wachtel", logoPlaceholder, 2022)}
      </ResponsiveCol>
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("", "Mario Crass", logoPlaceholder, 2022)}
      </ResponsiveCol>
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("", "Mark Oser", logoPlaceholder, 2022)}
      </ResponsiveCol>
    </Row>
    <Row type="flex">
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("", "Florian Ibach", logoFIbach, 2022)}
      </ResponsiveCol>
    </Row>

    <Divider/>
    <h3 className={styles.guttered}>Digitalausschuss:</h3>
    <Row type="flex">
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("", "Roland Lindt", logoRLindt, 2022)}
      </ResponsiveCol>
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("", "Florian Ibach", logoFIbach, 2014)}
      </ResponsiveCol>
      <ResponsiveCol count={3} className={styles.guttered}>
        {outerCard("", "Waldemar Wetzel", logoPlaceholder, 2014)}
      </ResponsiveCol>
    </Row>
  </>)
}

export default VorstandschaftPage;