import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './antd.css';
import 'react-image-timeline/dist/timeline.css';
import './react-image-timeline.css';
import './index.css';
import App from './App'; 
import * as serviceWorker from './serviceWorker';
import ReactGA from "react-ga";

const host = window.location.hostname;
const trackingId = Config.google.analytics[host];
ReactGA.initialize(trackingId);

ReactDOM.render(<App />, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
 