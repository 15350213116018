/* eslint-disable */

var egmWidget2 = new Object();
egmWidget2.url = "https://www.fussball.de/widget2";

egmWidget2.referer = "";
if (window.location.host) {
  egmWidget2.referer = window.Config.fussballDe.referer;
} else {
  egmWidget2.referer = "unknown";
}

const fussballdeWidgetAPI = function () {
  var D = new Object();
  var C = new Object();

  D.showWidget = function (E, K) {
    if (
      K != undefined &&
      K != null &&
      K != "" &&
      E != undefined &&
      E != null &&
      E != ""
    ) {
      if (document.getElementById(E)) {
        if (K != "") {
          var src =
            egmWidget2.url +
            "/-" +
            "/schluessel/" +
            K +
            "/target/" +
            E +
            "/caller/" +
            egmWidget2.referer;
          createIFrame(E, src);
        }
      } else {
        alert(
          "Der angegebene DIV mit der ID " + E + " zur Ausgabe existiert nicht."
        );
      }
    }
  };

  window.addEventListener("message", receiveMessage, false);

  function receiveMessage(event) {
    const querySelctor = document && document.querySelectorAll("#" + event.data.container + " iframe")[0];

    if (document && event.data.type === "setHeight" && document && querySelctor) {
      document
        .querySelectorAll("#" + event.data.container + " iframe")[0]
        .setAttribute("height", event.data.value + "px");
    }
    if (event.data.type === "setWidth" && document && querySelctor) {
      document
        .querySelectorAll("#" + event.data.container + " iframe")[0]
        .setAttribute("width", "100%");
    }
  }
  return D;
};

function createIFrame(parentId, src) {
  var parent = document.getElementById(parentId);
  var iframe = document.createElement("iframe");
  iframe.frameBorder = 0;
  iframe.setAttribute("src", src);
  iframe.setAttribute("scrolling", "no");
  iframe.setAttribute("width", "500px");
  iframe.setAttribute("height", "500px");
  iframe.setAttribute("style", "border: 1px solid #CECECE;");
  parent.innerHTML = "";
  parent.appendChild(iframe);
}

export default fussballdeWidgetAPI;