import * as React from "react";
import styles from "./TeamKader.module.css"
import { randomKey } from "../../framework-candidates/util/RandomKeyGen";

export type TeamKaderProps = {
  // TODO (fi): Spieler (mit Position)
  kader: Kader;
}
type AllProps = TeamKaderProps;

const TeamKader: React.FC<AllProps> = props => {
  const { kader } = props;

  const displayPosition = (position: string, persons: string[]) => {
    return (<p key={randomKey()} style={{paddingTop: "2px", paddingBottom: "2px"}}><b>{position}:</b> {persons.join(", ")}</p>)
  }

  const displayKader = () => {
    return Object.keys(kader)
      .map(k => displayPosition(k, kader[k]));
  }

  return (
    <>
      <h3 className={styles.heading}>Kader:</h3><span>{displayKader()}</span>
    </>
  );
}

export default TeamKader;