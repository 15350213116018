import * as React from "react";
import StadiumFoto, { StadiumFotoProps } from "./StadiumFoto";

type StadiumProps = {
  name: string;
  capacity: number;
}

type AllProps = StadiumProps & StadiumFotoProps;

const Stadium: React.FC<AllProps> = props => {
  const { capacity, logo, name } = props;

  return (
    <>
      <h3>{name}</h3>
      <b>Kapazität: </b>{capacity}
      <StadiumFoto logo={logo} />
    </>
  )
}

export default Stadium;