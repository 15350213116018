import * as React from "react";
import styles from "./AppHeader.module.css";
import logo from "../../imgs/fv.png";
import { Row, Col, Button, Icon } from "antd";
import MobileNavigation from "./Menu/MobileNavigation";
import { AppColors } from "../../AppColors";

const MobileHeader: React.FC<{}> = () => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  }

  return (
    <>
      <Row className={styles.header}>
        <Row type="flex" align={"middle"} justify="center" >
          {/* TODO (fi): move to css. */}
          <Col span={4} style={{justifyContent:"center", display:"flex"}}>
          <img src={logo} alt={"logo"} style={{height:"3em", position:"relative"}}/> 
          </Col>
          <Col span={16}>
            <h1>TuS Hügelsheim e.V.</h1>
          </Col>
          <Col span={4}>
            <Button id={styles.menuToggle} type="default" onClick={toggleCollapsed} style={{ marginBottom: 16, backgroundColor: AppColors.RED }}>
              <Icon type={isCollapsed ? 'menu-unfold' : 'menu-fold'} />
            </Button>
          </Col>
        </Row>
        <Row type="flex" className={styles.banderole} align={"middle"} justify="center">
          <span>&nbsp;</span>
          <MobileNavigation isCollapsed={isCollapsed} toggleCollapsed={toggleCollapsed} />
        </Row>
      </Row>
    </>)
}

export default MobileHeader;