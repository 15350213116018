import * as React from "react";
import { Route } from "react-router-dom";
import { NavItem } from "./components/layout/Menu/NavItem";
import { randomKey } from "./framework-candidates/util/RandomKeyGen";

type Props = {
  appLinks: Array<NavItem>;
}
type AllProps = Props;

const Routes = (props: AllProps) => {
  const { appLinks } = props;

  const renderRoute = (link: NavItem): any => {
    if (link.hasSubItems()) {
      return link.subItems.map((subLink: NavItem) =>
        renderRoute(subLink));
    }
    return <Route key={randomKey()} path={link.href} exact={link.exactMatch} component={link.component} />
  }

  const render = () => {
    return appLinks.map(link => !link.isExternal && renderRoute(link));
  }

  return (
    <>
      {render()}
    </>
  )
}

export default Routes;