import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

export class NavItem {
  href: string;
  icon: React.ComponentType<any>;
  iconProps: any = {};
  displayText: string;
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  isExternal: boolean = false;
  showInNavBar: boolean = true;
  exactMatch: boolean = false;
  subItems: Array<NavItem> = [];

  constructor(
    href: string,
    icon: React.ComponentType,
    displayText: string,
    component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>) {
    this.href = href;
    this.icon = icon;
    this.displayText = displayText;
    this.component = component;
  }

  hasSubItems = () => this.subItems.length > 0;

  external = () => {
    this.isExternal = true;
    return this;
  }

  hideInNavBar = () => {
    this.showInNavBar = false;
    return this;
  }

  hasToExactMatch = () => {
    this.exactMatch = true;
    return this;
  }

  withSubItem = (subItem: NavItem) => {
    this.subItems.push(subItem);
    return this;
  }

  withIconprops = (iconProps: any) => {
    this.iconProps = iconProps;
    return this;
  }
}