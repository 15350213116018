import * as React from "react";
import { Link } from "react-router-dom";
import { Path } from "../../Path";
import styles from "./Footer.module.css"; 

type AllProps = {}

const copyrightYear = () => `${new Date().getFullYear()}`.replace(/0/g, "O");

const Footer: React.FC<AllProps> = props => { 
  return (
    <div style={{ bottom: 0, position: "relative" }}>
      <footer className={styles.footer}>
        <Link to={Path.HOME}>© {copyrightYear()} TuS Hügelsheim</Link> | <Link to={Path.IMPRESSUM}>Impressum</Link> | <Link to={Path.DATENSCHUTZ}>Datenschutz</Link> | <Link to={Path.KONTAKT}>Kontakt</Link> 
      </footer>
    </div>
  )
}

export default Footer;