import * as React from "react";
import fussballdeWidgetAPI from "../../local_modules/fussball_de";

export type FussballDeWidgetProps = {
  widgetKey: string;
}
type AllProps = FussballDeWidgetProps;
const FussballDeWidget: React.FC<AllProps> = props => {
  const { widgetKey } = props;
  const [isFirstRun, setFirstRun] = React.useState(true);
  const divId = `widget-${widgetKey}`;

  React.useEffect(() => {
    if (isFirstRun) {
      const widgetAPI = new fussballdeWidgetAPI();
      widgetAPI.showWidget(divId, widgetKey);
      setFirstRun(false);
    }
  }, [isFirstRun, setFirstRun, widgetKey, divId])
  return <div id={divId} />
}

export default FussballDeWidget;