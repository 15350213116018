import {NavItem} from "./components/layout/Menu/NavItem";
import HomePage from "./pages/HomePage";
import Team1Page from "./pages/teams/aktive/Team1Page";
import AhPage from "./pages/teams/ah/AhPage";
// import JugendGeneralPage from "./pages/teams/youth/JugendGeneralPage";
import TeamBPage from "./pages/teams/youth/TeamBPage";
import TeamCPage from "./pages/teams/youth/TeamCPage";
import TeamDPage from "./pages/teams/youth/TeamDPage";
import TeamEPage from "./pages/teams/youth/TeamEPage";
import TeamFPage from "./pages/teams/youth/TeamFPage";
import TeamGPage from "./pages/teams/youth/TeamGPage";
import VorstandschaftPage from "./pages/VorstandschaftPage";
import HistoryPage from "./pages/HistoryPage";
import StadionPage from "./pages/StadionPage";
import SponsorsPage from "./pages/SponsorsPage";
import ImpressumPage from "./pages/footer/ImpressumPage";
import DatenschutzPage from "./pages/footer/DatenschutzPage";
import ContactPage from "./pages/footer/ContactPage";
import {FaHome, FaChild, FaUsers, FaPeopleCarry, FaShareAlt} from "react-icons/fa";
import {FaInstagram, FaFacebookF} from "react-icons/fa";
import {GiSoccerField, GiCornerFlag, GiBabyfootPlayers, GiSoccerBall, GiBookPile, GiWhistle} from "react-icons/gi";
import JerseyIcon from "./icons/JerseyIcon";
import {Path} from "./Path";
import FupaIcon from "./icons/FupaIcon";
import FussballDeIcon from "./icons/FussballDeIcon";
import SchiriPage from "./pages/SchiriPage";

const defaultIconProps = {className: "nav-icon-class ant-menu"};
const home = new NavItem(Path.HOME, FaHome, "Home", HomePage).withIconprops(defaultIconProps).hasToExactMatch();
const team1 = new NavItem(Path.TEAM_TEAM_1, JerseyIcon, "Herren", Team1Page);
const teamAh = new NavItem(Path.TEAM_AH, GiCornerFlag, "Alte Herren", AhPage).withIconprops(defaultIconProps);
// TODO: reenable
// const jugendAllgemein = new NavItem(Path.JUGEND_ALLGEMEIN, FaChild, "Unsere Jugend", JugendGeneralPage).withIconprops(defaultIconProps);
const teamB = new NavItem(Path.JUGEND_B, FaChild, "B-Jugend", TeamBPage).withIconprops(defaultIconProps);
const teamC = new NavItem(Path.JUGEND_C, FaChild, "C-Jugend", TeamCPage).withIconprops(defaultIconProps);
const teamD = new NavItem(Path.JUGEND_D, FaChild, "D-Jugend", TeamDPage).withIconprops(defaultIconProps);
const teamE = new NavItem(Path.JUGEND_E, FaChild, "E-Jugend", TeamEPage).withIconprops(defaultIconProps);
const teamF = new NavItem(Path.JUGEND_F, FaChild, "F-Jugend", TeamFPage).withIconprops(defaultIconProps);
const teamG = new NavItem(Path.JUGEND_G, FaChild, "G-Jugend", TeamGPage).withIconprops(defaultIconProps);
const jugend = new NavItem("", GiBabyfootPlayers, "Jugend")
  .withIconprops(defaultIconProps)
  // .withSubItem(jugendAllgemein)
  .withSubItem(teamB)
  .withSubItem(teamC)
  .withSubItem(teamD)
  .withSubItem(teamE)
  .withSubItem(teamF)
  .withSubItem(teamG);
const vorstand = new NavItem(Path.VEREIN_VORST, FaUsers, "Vorstandschaft", VorstandschaftPage).withIconprops(defaultIconProps);
const chronik = new NavItem(Path.VEREIN_HIST, GiBookPile, "Chronik", HistoryPage).withIconprops(defaultIconProps);
const verein = new NavItem("", GiSoccerBall, "Verein")
  .withIconprops(defaultIconProps)
  .withSubItem(vorstand)
  .withSubItem(chronik);
const stadion = new NavItem(Path.STADION, GiSoccerField, "Stadion", StadionPage).withIconprops(defaultIconProps);
const partner = new NavItem(Path.SPONSOREN, FaPeopleCarry, "Unsere Partner", SponsorsPage).withIconprops(defaultIconProps);
// TODO reenable
const schiri = new NavItem(Path.SCHIRI, GiWhistle, "Schiedsrichter", SchiriPage).withIconprops(defaultIconProps).hideInNavBar();
// const restaurant = new NavItem(Path.RESTAURANT, FaUtensils, "TuS Sportgaststätte", RestaurantPage).withIconprops(defaultIconProps);
const facebook = new NavItem(Config.socialMedia.facebook, FaFacebookF, "Facebook").external().withIconprops(defaultIconProps);
const instagram = new NavItem(Config.socialMedia.instagram, FaInstagram, "Instagram").external().withIconprops(defaultIconProps);
const fupa = new NavItem(Config.socialMedia.fupa, FupaIcon, "Fupa").external().withIconprops(defaultIconProps);
const fussballDe = new NavItem(Config.socialMedia.fussballDe, FussballDeIcon, "Fussball.de").external().withIconprops(defaultIconProps);
const socialMedia = new NavItem("", FaShareAlt, "Social Media").withIconprops(defaultIconProps)
  .withSubItem(facebook)
  .withSubItem(instagram)
  .withSubItem(fupa)
  .withSubItem(fussballDe);

const impressum = new NavItem(Path.IMPRESSUM, GiSoccerField, "Impressum", ImpressumPage).hideInNavBar();
const datenschutz = new NavItem(Path.DATENSCHUTZ, GiSoccerField, "Datenschutz", DatenschutzPage).hideInNavBar();
const kontakt = new NavItem(Path.KONTAKT, GiSoccerField, "Kontakt", ContactPage).hideInNavBar();
// TODO: Builder pattern! 
const AppLinks: Array<NavItem> = [
  home,
  team1,
  teamAh,
  jugend,
  verein,
  stadion,
  partner,
  schiri,
  // restaurant,
  socialMedia,

  impressum,
  datenschutz,
  kontakt,
]

export default AppLinks; 