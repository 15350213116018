import * as React from "react";
import Navigation from "./Navigation";
import AppLinks from "../../../AppLinks";

type AllProps = {
  isCollapsed: boolean;
  toggleCollapsed: () => void;
}

const MobileNavigation: React.FC<AllProps> = props => {
  const { isCollapsed, toggleCollapsed } = props;

  return (
    <Navigation appLinks={AppLinks} menuMode="inline" isCollapsed={isCollapsed} onOpenChange={toggleCollapsed} />
  )
}

export default MobileNavigation;