import * as React from "react";
import Banderole from "./layout/Banderole";
import logo from "../imgs/fv.png";
import useIsMobile from "../framework-candidates/hooks/useIsMobile";
import { AppColors } from "../AppColors";

type AllProps = {}
const CarouselOverlay: React.FC<AllProps> = props => {
  const isMobile = useIsMobile();

  return (<>
    <Banderole position={"bottom"} additionalStyles={{
      display: "flex", 
      justifyContent: "center", 
      alignItems: "center", 
      position: "absolute"
    }}>
      <h2 style={{
        color: AppColors.WHITE,
        transform: "translateY(6px) rotate(-1deg)",
        fontSize: isMobile ? "1.5em" : "3em",
        fontFamily: "barcaFont",
        zIndex: 100
      }}>Willkommen beim TuS</h2>
    </Banderole>
    <div style={{ display:"flex", justifyContent: "flex-end", alignItems: "center", height: "100%"}}>
      <img src={logo} alt="" style={{ width: isMobile ? "100px" : "200px", marginRight: "3%" }} />
    </div>
    <Banderole position={"top"} additionalStyles={{
      bottom: "0", position: "absolute", display: "flex", justifyContent: "center", alignItems: "center"
    }}>
      <h2 style={{
        color: AppColors.WHITE,
        transform: "translateY(-6px) rotate(-1deg)",
        fontSize: isMobile ? "1.5em" : "3em",
        fontFamily: "barcaFont",
        zIndex: 100
      }}>Zusammenhalt - Spass - Integration</h2>
    </Banderole>
  </>)
}

export default CarouselOverlay;