import * as React from "react";
import LightBoxImage from "../../framework-candidates/images/LightBoxImage";

export type TeamFotoProps = {
  logo: string;
  logoMin?: string;
  description?: string;
}
type AllProps = TeamFotoProps;

const TeamFoto: React.FC<AllProps> = props => {
  const { logo, logoMin } = props;
  
  // TODO (fi): description

  return (
    <LightBoxImage src={logo} srcMin={logoMin} alt={"Teamfoto"} />
  )
}

export default TeamFoto;