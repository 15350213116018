import * as React from "react";
import { CustomComponentsProps } from "react-image-timeline";
import LightBoxImage from "../../framework-candidates/images/LightBoxImage";

type AllProps = CustomComponentsProps;

const HistoryImage = (props: AllProps) => {
  const { imageUrl } = props.event;
  return (
    <LightBoxImage src={imageUrl} alt="" className={"rt-image"} />)
}

export default HistoryImage; 