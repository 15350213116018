import * as React from "react";
import styles from "./Banderole.module.css";
import { ChildrenProps } from "../../framework-candidates/propTypes";

type Props = {
  position: "top" | "bottom";
  additionalStyles?: React.CSSProperties;
}
type AllProps = Props & ChildrenProps;

const Banderole: React.FC<AllProps> = props => {
  const { position, additionalStyles, children } = props;
  const orientation = position === "top" ? styles.top : styles.bottom;
  return (
  <div className={`${styles.box} ${orientation}`} style={additionalStyles}>
    {children}
  </div>)
}

export default Banderole;