import * as React from "react";
import clubhaus from "../imgs/stadion/am_Clubhaus02.jpg";
import althreinstadion from "../imgs/stadion/Altrheinstadion.jpg";
import Stadium from "../components/stadium/Stadium";
import StadiumAddress from "../components/stadium/StadiumAddress";
import { Row, Col, PageHeader, Divider } from "antd";
import StadiumMaps from "../components/stadium/StadiumMaps";
import ResponsiveCol from "../components/layout/ResponsiveCol";

type AllProps = {}

const StadionPage: React.FC<AllProps> = props => {
  return (<>
    <PageHeader title="Unsere Stadien" />
    <Row type="flex" gutter={4}>
      <ResponsiveCol count={2}>
        <StadiumAddress />
      </ResponsiveCol>
      <ResponsiveCol count={2}>
      </ResponsiveCol>
    </Row>

    <StadiumMaps />
    <Divider />
    <Row type="flex" gutter={4}>
      <Col md={12} sm={24}>
        <Stadium name="Am Clubhaus" capacity={1100} logo={clubhaus} />
      </Col>
      <Col md={12} sm={24}>
        <Stadium name="Althreinstadion" capacity={2300} logo={althreinstadion} />
      </Col>
    </Row>
  </>)
}

export default StadionPage;