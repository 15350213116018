import * as React from "react";
import { PageHeader, Divider } from "antd";
import Email from "../../framework-candidates/common/Email";
import { randomKey } from "../../framework-candidates/util/RandomKeyGen";
import WebmasterEmail from "../../components/WebmasterEmail";
import ExternLink from "../../framework-candidates/common/ExternLink";

type ContactType = "Email" | "Telefon" | "Facebook";
type Contact = {
  type: ContactType;
  contact: any;
}

const contacts = {
  webmaster: [{ type: "Email", contact: <WebmasterEmail /> }],
  jugend: [{ type: "Email", contact: <Email mail="jugend" /> }],
  restaurant: [
    { type: "Email", contact: <Email mail="gaststaette" /> },
    { type: "Telefon", contact: "+49172 7487315" }
  ],
  ah: [
    { type: "Email", contact: <Email mail="alte.herren" /> },
    { type: "Telefon", contact: "07229/1851963" }
  ],
  herren: [{type: "Facebook", contact: <ExternLink href={Config.socialMedia.facebook}>Facebook</ExternLink>}],
  sponsoring: [{type: "Email", contact: <Email mail="sponsoring" />}]
}

const ContactPage = () => {

  const renderCardContent = (contact: Contact) => {
    return (<React.Fragment key={randomKey()}>
      <p><b>{contact.type}:</b></p>
      <p>{contact.contact}</p>
    </React.Fragment>)
  }

  const renderCard = (description: string, contacts: Array<any>) => {
    return (<>
      <h3>{description}:</h3>
      <div>
        {contacts.map(renderCardContent)}
      </div>
      <Divider />
    </>)
  }

  return (<> 
    <PageHeader title={"Kontakt"} />
    <p>Hier finden Sie alle Kontaktmöglichkeiten auf einen Blick:</p>
    <Divider />
    {renderCard("Webmaster", contacts.webmaster)}
    {renderCard("Herren", contacts.herren)}
    {renderCard("Jugend", contacts.jugend)}
    {renderCard("Alte Herren", contacts.ah)}
    {renderCard("Partner", contacts.sponsoring)}
    {renderCard("Sportgaststätte", contacts.restaurant)}
  </>)
}

export default ContactPage;