import React from 'react';
import './App.css';
import AppHeader from './components/layout/AppHeader';
import Content from './components/layout/Content';
import Layout from './components/layout/Layout';
import useIsMobile from './framework-candidates/hooks/useIsMobile';
import MobileHeader from './components/layout/MobileHeader';
import { BackTop } from 'antd';
import Footer from './components/layout/Footer';
import AnalyticsRouter from './framework-candidates/routes/AnalyticsRouter';

const App: React.FC = () => {
  const isMobile = useIsMobile();
  
  return (
    <>
      <BackTop visibilityHeight={0} />
      <Layout>
        <AnalyticsRouter>
          {isMobile ? <MobileHeader /> : <AppHeader />}
          <Content />
          <Footer />
        </AnalyticsRouter>
      </Layout>
    </>
  );
}

export default App;
