import * as React from "react";
import { ChildrenProps } from "../propTypes";

type Props = {
  foreground: React.ReactNode;
  display: () => boolean
}
type AllProps = Props & ChildrenProps;

const Overlay: React.FC<AllProps> = props => {
  const { foreground, display, children } = props;

  return (<>
    <div 
      style={{height: "auto", width: "auto", position:"relative"}}>
      <div id="overlay"
        style={{ position: "absolute", zIndex: 100, width: "100%", height: "100%", 
          display: display()?"block": "none"}}>
        {foreground}
      </div>
      {children}
    </div>
  </>)
}

export default Overlay;