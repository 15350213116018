import * as React from "react";
import styles from "./Content.module.css";
import Routes from "../../Routes";
import AppLinks from "../../AppLinks";

const Content: React.FC = () => {
  return <>
    <div className={styles.content}>
      <Routes appLinks={AppLinks} />
    </div> 
  </>
}

export default Content;