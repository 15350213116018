import * as React from "react";
import {ChildrenProps} from "../../../framework-candidates/propTypes";
import Team from "../../../components/Team/Team";
import logo from "../../../imgs/youth/2022_23/g_jugend.jpg"

//TODO (fi): hier in general verschieben
type TeamProps = {};
type AllProps = ChildrenProps;

const TeamGPage: React.FC<AllProps> = props => {
  return <>
    <Team
      title={"G-Jugend"}
      trainer={"Wadim Wachtel"}
      spielklasse={"Spielenachmittage"}
      trainingstermine={"Dienstag, Mittwoch 17:00 - 18:30 Uhr"}
      logo={logo}
      kader={{}}
    />
  </>
}

export default TeamGPage;