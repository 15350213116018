import Sponsor from "./Sponsor";
import SponsorType from "./SponsorType";
import logoGruenerBaum from "../../imgs/sponsors/gold/Gruner-Baum-fertig.jpg";
import logoMack from "../../imgs/sponsors/gold/mack-immobilien.png";
import logoASRaz from "../../imgs/sponsors/gold/RAZ HomePage.jpeg";
import logoAirpark from "../../imgs/sponsors/gold/baden_airpark.jpg";

const goldSponsors: Sponsor[] = [
  {
    Name: "Grüner Baum",
    Homepage: "https://www.gruener-baum.info/",
    SponsorType: SponsorType.Gold,
    Img: logoGruenerBaum
  },
  {Name: "Mack-Immobilien", Homepage: "https://www.mack.immo/", SponsorType: SponsorType.Gold, Img: logoMack},
  {Name: "A & S RAZ GbR", Homepage: "https://www.fa-raz.de/", SponsorType: SponsorType.Gold, Img: logoASRaz},
  {
    Name: "Flughafen Baden Airpark",
    Homepage: "https://www.baden-airpark.de/",
    SponsorType: SponsorType.Gold,
    Img: logoAirpark
  }
];

export default goldSponsors;