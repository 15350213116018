import * as React from "react";

type Props = {
  svg: string;
}
type AllProps = Props;

const SvgIcon = (props: AllProps) => {
  const { svg } = props;
  // TODO (fi): style to own css file
  return <img alt=""
    src={svg}
    style={{
      width: "1.17em",
      height: "auto",
      position: "relative",
      verticalAlign: "sub"
    }}
  />
}
export default SvgIcon;