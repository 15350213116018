import * as React from "react";
import Navigation from "./Navigation";
import AppLinks from "../../../AppLinks";

type AllProps = {}
const DesktopNavigation: React.FC<AllProps> = props => {

  return <Navigation appLinks={AppLinks} menuMode={"horizontal"} isCollapsed={false} />
}

export default DesktopNavigation;