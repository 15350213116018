import * as React from "react";
import { ChildrenProps } from "../propTypes";

type Props = {}
type AllProps = Props & ChildrenProps & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const ExternLink = (props: AllProps) => {
  const {children, ...rest} = props;
  return (<>
    <a {...rest} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  </>);
}

export default ExternLink;