import * as React from "react";
import { Descriptions } from "antd";

export type TeamShortInfoProps = {
  spielklasse?: string;
  trainer: string;
  trainingstermine: string;
  spielausschuss?: string;
  description?: any;
  additionals?: { key: string, value: string }[]
}
type AllProps = TeamShortInfoProps;

const TeamShortInfo: React.FC<AllProps> = props => {
  const { spielklasse, trainer, trainingstermine, spielausschuss, description, additionals } = props;

  const calcAdditionals = () => {
    return additionals && 
      additionals.map(a => <Descriptions.Item key={a.key} label={a.key}>{a.value}</Descriptions.Item>)
  }

  return (
    <Descriptions title="Kurzinformation" column={1}>
      {spielklasse && <Descriptions.Item label="Spielklasse">{spielklasse}</Descriptions.Item>}
      <Descriptions.Item label="Trainer">{trainer}</Descriptions.Item>
      {spielausschuss && <Descriptions.Item label="Spielausschuss">{spielausschuss}</Descriptions.Item>}
      <Descriptions.Item label="Trainingstermine">{trainingstermine}</Descriptions.Item>
      {description && <Descriptions.Item label="">{description}</Descriptions.Item>}
      {calcAdditionals()}
    </Descriptions>
  )
}

export default TeamShortInfo;