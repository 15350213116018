import * as React from "react";
import { CustomComponentsProps } from "react-image-timeline";

type AllProps = CustomComponentsProps;

const HistoryHeader = (props: AllProps) => {
  const { date, extras } = props.event;

  const headline = () => {
    if (extras && extras.range) {
      return extras.range;
    }
    return date.getFullYear();
  }

  return (
    <h2 className={"rt-title"}>{headline()}</h2>
  )
}

export default HistoryHeader;  