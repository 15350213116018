import * as React from "react";
import { Card } from "antd";

type AllProps = {}

const StadiumAddress: React.FC<AllProps> = props => {
  return (<>
    <Card
      size={"small"}
      title={"Adresse:"}
      bordered={false}
      headStyle={{ fontWeight: "bold" }}
    >
      <p>TuS Hügelsheim e.V.</p>
      <p>Vogesenstr. 21b</p>
      <p>76549 Hügelsheim</p>
    </Card>
  </>)
}

export default StadiumAddress;