const importAll = require =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {});

// zweiter Parameter (true/false) = includeSubDirectories
const carouselImages = importAll(require.context("./carousel/", false, /\.(png|jpe?g|svg)$/));
const youth19_20Images = importAll(require.context("./youth/2019_20", false, /\.(png|jpe?g|svg)$/));
const sponsors_all = importAll(require.context("./sponsors/Others", true, /\.(png|jpe?g|svg)$/));
export { carouselImages, youth19_20Images, sponsors_all };