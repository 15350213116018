import * as React from "react";
import MouseHand from "./MouseHand";
import { IoIosAt } from "react-icons/io";

type Props = {
  mail: string;
  host?: string;
}
type AllProps = Props;

const Email = (props: AllProps) => {
  const { mail, host } = props;
  const cleanedHost = () => window.location.host.replace('www.', '');
  const calcHost = () => host ? host : cleanedHost();
  const maskedMail = () => {
    return (<>
      <span>{mail}</span><IoIosAt /><span>{calcHost()}</span>
    </>)
  }
  const plaintextMail = () => `${mail}@${calcHost()}`;
  const combineHref = () => `mail${"to"}:${plaintextMail()}`;
  const click = () => window.open(combineHref());
  return (<>
    <MouseHand>
      <span onClick={click}>{maskedMail()}</span>
    </MouseHand>
  </>);
}

export default Email;