import * as React from "react";
import { Tabs } from "antd";
import FussballDeWidget from "./FussballDeWidget";

type TabPane = {
  tabDescription: string
  widgetKey: string;
}

export type TeamResultProps = {
  tabs: TabPane[];
}
type AllProps = TeamResultProps;

const TeamResults: React.FC<AllProps> = props => {
  const { tabs } = props;

  const calculateTabs = () => {
    return tabs.map((tab, index) => {
      return <Tabs.TabPane key={`${index}`} tab={tab.tabDescription} forceRender={true}>
        <FussballDeWidget  widgetKey={tab.widgetKey} />
      </Tabs.TabPane>
    })
  }

  return (<>
    <Tabs style={{ fontFamily: "barcaFont" }}>
      {calculateTabs()}
    </Tabs>
  </>)
}

export default TeamResults;