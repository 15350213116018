import * as React from "react";
import Team1Logo from "../imgs/icons/aktive.svg";
import SvgIcon from "./SvgIcon";

type AllProps = {}

const JerseyIcon = (props: AllProps) => {
  return <SvgIcon svg={Team1Logo} />
}

export default JerseyIcon;