export enum Path {
  HOME = "/",
  TEAM_TEAM_1 = "/teams/team1",
  TEAM_AH = "/teams/ah",
  JUGEND_ALLGEMEIN = "/jugend",
  JUGEND_B = "/teams/b",
  JUGEND_C = "/teams/c",
  JUGEND_D = "/teams/d",
  JUGEND_E = "/teams/e",
  JUGEND_F = "/teams/fc", 
  JUGEND_G = "/teams/g",
  VEREIN_HIST = "/verein/chronik",
  VEREIN_VORST = "/verein/vorstand",
  STADION = "/stadion",
  SPONSOREN = "/partner",
  SCHIRI = "/schiedsrichter",
  RESTAURANT = "/restaurant",
  
  IMPRESSUM = "/impressum",
  DATENSCHUTZ = "/datenschutz",
  KONTAKT = "/kontakt",
}