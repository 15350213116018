import * as React from "react";
import Team from "../../../components/Team/Team";
import logo from "../../../imgs/ah/ah-2018.jpg";

type AllProps = {}

const AhPage: React.FC<AllProps> = props => {
  return <>
    <Team
      title={"Alte Herren"}
      trainer={"Nino Kehrer"}
      trainingstermine={"Donnerstag 19:00 Uhr"}
      logo={logo}
      kader={{}}
      additionals={[{key:"Kontakt", value:"Nino Kehrer 07229/1851963"}]}
    />
   
   <div>
     <p>Wir trainieren immer Donnerstags ab 19:00 Uhr auf unserem Sportplatz. Von Dezember bis einschließlich Februar verbringen wir die Wintersaison ab 20:30 Uhr in der Ontariohalle. Bei uns steht nicht nur der Fußball im Mittelpunkt. <br />
     Ausflüge mit oder ohne unseren Frauen und anschließender Pflege der Kameradschaft runden das Programm ab. <br />
     Wir organisieren uns über einen Emailverteiler und eine geschlossene Facebook Gruppe. Gastspieler und Neuzugänge sind immer willkommen.</p>
    </div>
  </>
}

export default AhPage; 