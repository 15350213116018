import * as React from "react";
import {ChildrenProps} from "../../../framework-candidates/propTypes";
import Team from "../../../components/Team/Team";
import logo from "../../../imgs/youth/2022_23/f_jugend.jpg";

//TODO (fi): hier in general verschieben
type TeamProps = {};
type AllProps = ChildrenProps;

const TeamFPage: React.FC<AllProps> = props => {
  return <>
    <Team
      title={"F-Jugend"}
      trainer={"Sophie Ullrich, Joachim Ullrich"}
      spielklasse={"Spielenachmittage"}
      trainingstermine={"Dienstag, Mittwoch 17:30 - 19:00 Uhr"}
      logo={logo}
      kader={{}}
    />
  </>
}

export default TeamFPage;