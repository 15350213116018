import * as React from "react";
import styles from "./AppHeader.module.css";
import logo from "../../imgs/fv.png";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import DesktopNavigation from "./Menu/DesktopNavigation";

type AllProps = {}

const AppHeader: React.FC<AllProps> = () => {
  return (
    <>
      <Row className={styles.header}>
        <Link to="/"><img src={logo} alt={"logo"} /></Link>
        <Row type="flex" align={"middle"} justify="center" >
          <Col><h1>TuS Hügelsheim 1924 e.V. - Abteilung Fussball</h1></Col>
        </Row>
        <Row type="flex" className={styles.banderole} align={"middle"} justify="center">
          <Col span={18}>
            <DesktopNavigation />
          </Col>
        </Row>
      </Row>
    </>
  )
}

export default AppHeader;