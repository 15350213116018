import * as React from "react";
import { PageHeader } from "antd";

type Props = {}
type AllProps = Props;

const SchiriPage = (props: AllProps) => {
  return (
    <PageHeader title="Unsere Schiedsrichter" />
  )
}
export default SchiriPage;