import * as React from "react";
import { PageHeader, Divider } from "antd";
import { Path } from "../../Path";
import { Link } from "react-router-dom";
import WebmasterEmail from "../../components/WebmasterEmail";
import Email from "../../framework-candidates/common/Email";

type AllProps = {}

const ImpressumPage = (props: AllProps) => {
  return (<>
    <PageHeader title="Impressum" />
    <h3>TuS Hügelsheim e.V.</h3>
    <p>Damir Roscic</p>
    <p>Abteilungsleiter Fußball</p>
    <p>Handwerker Str. 11</p>
    <p>76549 Hügelsheim</p>
    <p>Tel. +49 1511 1106 015</p>
    <p><Email mail={"damir.roscic"} /></p>
    <Divider />
    <h3>Registergericht</h3>
    <p>Amtsgericht Mannheim</p>
    <p>Registernummer: VR 210315</p>
    <Divider />
    <h3>Technik und Redaktion</h3>
    <p>Florian Ibach und Johannes Ibach</p>
    <p>Kontakt: <WebmasterEmail /></p>
    <p>Trotz inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der externen Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
    <Divider />
    <h3>Datenschutzerklärung</h3>
    <p>Informationen zum Thema Datenschutz finden Sie <Link to={Path.DATENSCHUTZ}>hier</Link></p>
  </>)
}

export default ImpressumPage;