import * as React from "react";
import Timeline, { Event } from "react-image-timeline"
import HistoryHeader from "../components/history/HistoryHeader";
import { PageHeader } from "antd";
/* Images */
import gruendungTurnen from "../imgs/chronik/turner.jpg";
import gruendungFussball from "../imgs/chronik/27.png";
import gruendungTuS from "../imgs/chronik/32_2.png";
import umzugSportplatz from "../imgs/chronik/1_1.png";
import Jubiläum50 from "../imgs/chronik/33_2.png";
import bauAlthreinstadion from "../imgs/chronik/35.png";
import bauClubhaus from "../imgs/chronik/back.png";
import sanierungClubhaus from "../imgs/chronik/45.png";
import mitglied1000 from "../imgs/chronik/65.png";
import jugendFoerderpreis from "../imgs/chronik/jugendförderpreis.jpg";
import sportVerbindet from "../imgs/chronik/sport_verbindet_ehrenurkunde.png"
import seppHerberger from "../imgs/chronik/sepp_Herberger.png";
import egidiusBraun from "../imgs/chronik/egidius_braun_preis.jpg";

import meister1988 from "../imgs/chronik/23.png";
import meister1990 from "../imgs/chronik/37.png";
import aufstieg1992 from "../imgs/chronik/43.png";
import meister1995 from "../imgs/chronik/53_col.jpg";
import aJugendStaffelSieg from "../imgs/chronik/staffelsieger_a.jpg";
import meister2009 from "../imgs/chronik/Meistermannschaft_2009.png";
import vizemeister2012 from "../imgs/chronik/2012_0.jpg";
import futsal2014 from "../imgs/chronik/futsal_1.png";
import HistoryImage from "../components/history/HistoryImage";

const year = (year: number) => new Date(year, 6, 1);
const extras = (range: string) => { return { range } }

const events: Array<Event> = [
  { date: year(1914), text: "Gründung des Turnverein Hügelsheim", imageUrl: gruendungTurnen },
  { date: year(1924), text: "Gründung des Sportverein Hügelsheim", imageUrl: gruendungFussball },
  { date: year(1936), text: "Zusammenlegung beider Vereine zum TuS Hügelsheim (durch eine allgemeine Verfügung des Reichssportführers)", imageUrl: gruendungTuS },
  { date: year(1952), text: "Umzug des Sportplatzes wegen regelmäßiger Überschwemmung des Rheins an den heutigen Standort am \"Hang\"", imageUrl: umzugSportplatz },
  { date: year(1974), text: "50 jähriges Jubiläum", imageUrl: Jubiläum50 },
  { date: year(1985), text: "Bau des Altrheinstadion", imageUrl: bauAlthreinstadion, extras: extras("1984 - 1985") },
  { date: year(1990), text: "Bau des Clubhauses. Eröffnung am 15.09.90 mit Pächterin Karin Frick", imageUrl: bauClubhaus, extras: extras("1989-1990") },
  { date: year(1995), text: "Sanierung des Sportplatzes am Clubhaus und Anlegung der Hangbefestigung", imageUrl: sanierungClubhaus, extras: extras("1994-1995") },
  { date: new Date(1996, 10, 4), text: "Das 1000. Mitglied des TuS wird geehrt", imageUrl: mitglied1000 },
  { date: new Date(1998, 11, 18), text: "Der TuS Hügesheim erhält den Jugendförderpreis 1997/1998 für \"beispielhafte Vereinsjugendarbeit\"", imageUrl: jugendFoerderpreis },
  { date: new Date(1999, 7, 1), text: "DFB-Stiftung \"Sepp Herberger\" zeichnet den TuS Hügelsheim \"für eine besonders bemerkenswerte Jugendarbeit\" aus", imageUrl: seppHerberger },
  { date: new Date(1999, 4, 24), text: "Verleihung der Ehrenurkunde für \"außergewöhnlich engagierten Einsatz um die Förderung des Gemeinschaftsgedankens im Sport\" durch die Deutsche Olympische Gesellschaft", imageUrl: sportVerbindet },
  { date: year(2018), text: "Verleihung des Egidius-Braun-Preises des DFB für \"Vorbildliche Arbeit mit Flüchtlingen\"", imageUrl: egidiusBraun },
  /* sportliche erfolge */
  { date: year(1988), text: "Meister Kreisliga B und Aufstieg in die Kreisliga A", imageUrl: meister1988 },
  { date: year(1990), text: "Meister Kreisliga A und Aufstieg in die Bezirksliga", imageUrl: meister1990 },
  { date: year(1992), text: "Bezirkspokalsieger und Aufstieg in die Bezirksliga nach Relegationsspiel gegen Muggensturm", imageUrl: aufstieg1992 },
  { date: year(1995), text: "Meister Kreisliga A und Aufstieg in die Bezirskliga", imageUrl: meister1995 },
  { date: year(1998), text: "A-Jugend südbadischer Staffelsieger und Aufstieg in die Verbandsstaffel", imageUrl: aJugendStaffelSieg },
  { date: year(2009), text: "Meister Kreisliga B und Aufstieg in die Kreisliga A", imageUrl: meister2009 },
  { date: year(2012), text: "Vizemeister der Kreisliga A", imageUrl: vizemeister2012 },
  { date: year(2014), text: "Futsal Bezirksmeister", imageUrl: futsal2014 },
]
type AllProps = {}
const HistoryPage: React.FC<AllProps> = props => {
  const empty = () => <></>;
  const bottomLabel = () => <div className="rt-label">Heute</div>
  const topLabel = () => <div className="rt-label" />;

  return (<>
    <PageHeader title={"Die Chronik des TuS Hügelsheim"} />
    <Timeline events={events}
      customComponents={{
        header: HistoryHeader,
        footer: empty,
        topLabel: topLabel,
        bottomLabel: bottomLabel,
        imageBody: HistoryImage
      }}
    />
  </>)
}

export default HistoryPage;