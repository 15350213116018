import * as React from "react";
import { Menu, Icon } from "antd";
import { ClickParam, MenuMode } from "antd/lib/menu";
import { Link } from "react-router-dom";
import SubMenu from "antd/lib/menu/SubMenu";
import ExternLink from "../../../framework-candidates/common/ExternLink";
import { NavItem } from "./NavItem";
import { randomKey } from "../../../framework-candidates/util/RandomKeyGen";

type Props = {
  appLinks: Array<NavItem>;
  menuMode: MenuMode;
  isCollapsed: boolean;
  onOpenChange?: (openKeys: string[]) => void
}
type AllProps = Props;


const Navigation: React.FC<AllProps> = props => {
  const { menuMode, isCollapsed, onOpenChange, appLinks } = props;

  const handleClick = (param: ClickParam) => {
    onOpenChange && onOpenChange([param.key]);
  }

  const renderDisplayText = (link: NavItem) => <><link.icon {...link.iconProps} /><span>{"  "}{link.displayText}</span></>

  const renderLink = (link: NavItem) => {
    if (link.isExternal) {
      return <ExternLink href={link.href}>{renderDisplayText(link)}</ExternLink>
    }
    return <Link to={link.href}>{renderDisplayText(link)}</Link>
  }

  const renderMenu = (link: NavItem) => {
    if (link.hasSubItems()) {
      return (
        <SubMenu key={randomKey()} title={renderDisplayText(link)}>
          {link.subItems.map(subLink => renderMenu(subLink))}
        </SubMenu>
      )
    }
    return <Menu.Item key={randomKey()}>{renderLink(link)}</Menu.Item>
  }

  const render = () => {
    return appLinks.map((link: NavItem) =>
      link.showInNavBar && renderMenu(link));
  }

  const overflowedIndicator = <span><Icon type="plus-circle" /> Mehr</span>

  return (
    <>
      {!isCollapsed && <Menu
        onClick={handleClick}
        selectedKeys={[]}
        mode={menuMode}
        theme="dark"
        overflowedIndicator={overflowedIndicator}
        forceSubMenuRender={true}
      >
        {render()}
      </Menu>}
    </>
  )
}

export default Navigation;