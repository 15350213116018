import * as React from "react";
import { Row, Col } from "antd";
import { ChildrenProps } from "../../framework-candidates/propTypes";
import styles from "./Layout.module.css";

type AllProps = ChildrenProps;

const Layout: React.FC<AllProps> = props => {
  const { children } = props;

  return (
    <>
      <Row type="flex" style={{ minHeight: "100vh" }}>
        <Col
          className={styles.container}
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 22, offset: 1 }}
          md={{ span: 16, offset: 4 }}
        >
          {children}
        </Col>
      </Row>
    </>
  )
}

export default Layout;