import * as React from "react";
import LightBoxImage from "../../framework-candidates/images/LightBoxImage";

export type StadiumFotoProps = {
  logo: string;
}
type AllProps = StadiumFotoProps

const StadiumFoto: React.FC<AllProps> = props =>{
  const {logo} = props;

  return (
    <LightBoxImage src={logo} alt={"Stadion"} />
  )
}

export default StadiumFoto;