import Sponsor from "./Sponsor";
import SponsorType from "./SponsorType";
import logoTrapp from "../../imgs/sponsors/Others/Trapp.png"
import logoFalken from "../../imgs/sponsors/Others/Falken Apotheke.png"
import logoSparkasse from "../../imgs/sponsors/Others/sparkasse-rastatt.jpg"
import logoJehle from "../../imgs/sponsors/Others/Jehle_Logo.svg"
import logoVogel from "../../imgs/sponsors/Others/Vogel.jpg"
import logoWeberHaus from "../../imgs/sponsors/Others/logo-weberhaus.svg"
import logoBaeckerei from "../../imgs/sponsors/Others/Bäckerei.png"
import logoEis from "../../imgs/sponsors/Others/Eis.png"
import logoVr from "../../imgs/sponsors/Others/vr-bank.jpg"
import logoHatz from "../../imgs/sponsors/Others/Hatz.png"
import logoWertheimer from "../../imgs/sponsors/Others/Wertheimer_Logo.jpg"
import logoHaenssel from "../../imgs/sponsors/Others/haenssel.png"
import logoBenz from "../../imgs/sponsors/Others/bau_benz.jpg"
import logoTeinacher from "../../imgs/sponsors/Others/teinacher_logo.svg"
import logoZoller from "../../imgs/sponsors/Others/zoller.png"
import logoKruezi from "../../imgs/sponsors/Others/kruezi.jpg"
import logoNaber from "../../imgs/sponsors/Others/naber_druck.png"
import logoWaas from "../../imgs/sponsors/Others/physio_markus_waas.png"
import logoBaeuerle from "../../imgs/sponsors/Others/baeuerle.png"

const otherSponsors: Sponsor[] = [
  {
    Name: "Trapp u. Co. GmbH",
    Homepage: "https://www.trapp-heizung.de/",
    SponsorType: SponsorType.Other,
    Img: logoTrapp
  },
  {Name: "Falken-Apotheke", Homepage: "https://www.gesundund.net/", SponsorType: SponsorType.Other, Img: logoFalken},
  {
    Name: "Sparkasse Rastatt-Gernsbach",
    Homepage: "https://www.spk-rastatt-gernsbach.de/de/home.html",
    SponsorType: SponsorType.Other,
    Img: logoSparkasse
  },
  {Name: "Jehle GmbH", Homepage: "https://www.jehle-gmbh.de/", SponsorType: SponsorType.Other, Img: logoJehle},
  {
    Name: "Umweltpartner Vogel GmbH",
    Homepage: "https://www.kompost-vogel.de/",
    SponsorType: SponsorType.Other,
    Img: logoVogel
  },
  {Name: "WeberHaus GmbH", Homepage: "https://www.weberhaus.de/", SponsorType: SponsorType.Other, Img: logoWeberHaus},
  {
    Name: "Eis Florenz",
    Homepage: "https://www.facebook.com/pages/Eis-Florenz-H%C3%BCgelsheim/753228171510346?__tn__=C-R",
    SponsorType: SponsorType.Other,
    Img: logoEis
  },
  {Name: "Bäckerei Leppert", SponsorType: SponsorType.Other, Img: logoBaeckerei},
  {
    Name: "VR-Bank",
    Homepage: "https://www.vr-miba.de/wir-fuer-sie/filialen-ansprechpartner/filialen/filialsuche/huegelsheim.html",
    SponsorType: SponsorType.Other,
    Img: logoVr
  },
  {Name: "Hatz-Moninger", Homepage: "http://www.hatz-moninger.de/", SponsorType: SponsorType.Other, Img: logoHatz},
  {Name: "Wertheimer", Homepage: "https://wertheimer.de/", SponsorType: SponsorType.Other, Img: logoWertheimer},
  {
    Name: "Hänßel Mineralöl Gmbh",
    Homepage: "https://www.haenssel-gmbh.de/",
    SponsorType: SponsorType.Other,
    Img: logoHaenssel
  },
  {
    Name: "Bruno Benz Bauunternehmung GmbH",
    Homepage: "https://www.benz-bau.de/",
    SponsorType: SponsorType.Other,
    Img: logoBenz
  },
  {
    Name: "Mineralbrunnen Teinach GmbH",
    Homepage: "https://www.teinacher.de/",
    SponsorType: SponsorType.Other,
    Img: logoTeinacher
  },
  {
    Name: "Metzgerei Zoller",
    Homepage: "https://www.metzgerei-zoller.de/home/",
    SponsorType: SponsorType.Other,
    Img: logoZoller
  },
  {Name: "Kruezi", Homepage: "https://kruezi-sandstrahlen.de/", SponsorType: SponsorType.Other, Img: logoKruezi},
  {Name: "naberDRUCK GmbH", Homepage: "https://naberdruck.de/", SponsorType: SponsorType.Other, Img: logoNaber},
  {Name: "Physiotherapie Markus Waas", Homepage: "https://waas-physiotherapie.de/", SponsorType: SponsorType.Other, Img: logoWaas},
  {Name: "bäuerle - das werbewerk.", Homepage: "https://genialgemacht.de/", SponsorType: SponsorType.Other, Img: logoBaeuerle}
];

export default otherSponsors;