import * as React from "react";
import { ChildrenProps } from "../../framework-candidates/propTypes";
import { Col } from "antd";
import useIsMobile from "../../framework-candidates/hooks/useIsMobile";

type Props = {
  className?: string;
  idName?: string;
  count: number;
}
type AllProps = Props & ChildrenProps;

const maxCols = 24;

const ResponsiveCol = (props: AllProps) => {
  const { children, className, idName, count } = props;
  const isMobile = useIsMobile();
  const mdSpan = () => maxCols / count;

  return (
    <Col
      className={className}
      id={idName}
      span={isMobile?24:mdSpan()}
      offset={0}      
    >
      {children}
    </Col>
  )
}

export default ResponsiveCol;