import * as React from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import MouseHand from "../common/MouseHand";

export type LightBoxImageProps = {
  src: string;
  srcMin?: string;
  alt: string;
  className?: string;
}
type AllProps = LightBoxImageProps;

const LightBoxImage: React.FC<AllProps> = props => {
  const { src, alt, srcMin: srcSmall, ...rest } = props;
  const [showLightbox, setShowLightbox] = React.useState(false);

  const onImageClick = () => setShowLightbox(!showLightbox);

  const onLightBoxCloseClick = () => setShowLightbox(false);

  return (<>
    <MouseHand>
      <img src={srcSmall ? srcSmall : src} alt={alt} onClick={onImageClick} {...rest} />
    </MouseHand>
    {showLightbox &&
      <Lightbox
        mainSrc={src}
        onCloseRequest={onLightBoxCloseClick}
      />}
  </>)
}

export default LightBoxImage;
