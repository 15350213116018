import * as React from "react";
import { PageHeader, Row, Col } from "antd";
import TeamShortInfo, { TeamShortInfoProps } from "./TeamShortInfo";
import TeamFoto, { TeamFotoProps } from "./TeamFoto";
import TeamKader, { TeamKaderProps } from "./TeamKader";

type Props = {
  title: string;
}
type AllProps = Props & TeamShortInfoProps & TeamKaderProps & TeamFotoProps;

const Team: React.FC<AllProps> = props => {
  const { title, spielklasse, trainer, spielausschuss, trainingstermine, description, additionals } = props;
  const { logo, logoMin } = props;
  const { kader } = props;

  const subTitle = `Saison ${Config.fussballDe.season}`;

  return (
    <>
      <PageHeader title={title} subTitle={subTitle} backIcon={false} />
      <Row type={"flex"} gutter={16}>
        <Col md={12} sm={24}><TeamFoto logo={logo} logoMin={logoMin} /></Col>
        <Col md={12} sm={24}>
          <TeamShortInfo
            spielklasse={spielklasse}
            trainer={trainer}
            spielausschuss={spielausschuss}
            trainingstermine={trainingstermine}
            description={description}
            additionals={additionals}
          />
        </Col>
      </Row>
      <Row type="flex">
        <Col>{Object.keys(kader).length > 0 &&
          <TeamKader kader={kader} />}</Col>
      </Row>
      {/* TODO (fi): hier  */}
    </>
  )
}

export default Team;