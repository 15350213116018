import * as React from "react";
import {ChildrenProps} from "../../../framework-candidates/propTypes";
import Team from "../../../components/Team/Team";
import logo from "../../../imgs/youth/2022_23/e_jugend.jpg";
import TeamResults from "../../../components/Team/TeamResults";

//TODO (fi): hier in general verschieben
type TeamProps = {};
type AllProps = ChildrenProps;

const TeamEPage: React.FC<AllProps> = props => {
  const config = Config.e;
  return <>
    <Team
      title={"E-Jugend"}
      trainer={config.trainer}
      spielklasse={config.spielklasse}
      trainingstermine={config.trainingstermine}
      logo={logo}
      kader={{}}
    />
    {<TeamResults tabs={
      [{tabDescription: "Tabelle", widgetKey: config.fussballDe.tabelle},
        {tabDescription: "Ergebnisse", widgetKey: config.fussballDe.ergebnisse}]
    }/>}
  </>
}

export default TeamEPage;