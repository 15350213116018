import * as React from "react";
import { ChildrenProps } from "../propTypes";

type AllProps = ChildrenProps;

const MouseHand: React.FC<AllProps> = props => {
  const {children} = props;

  return <><span style={{cursor:"pointer"}}>{children}</span></>
}
export default MouseHand;