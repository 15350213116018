import * as React from "react";
import Image from "../imgs/icons/fupa.svg";
import SvgIcon from "./SvgIcon";

type AllProps = {};

const FupaIcon = (props: AllProps) => {
  return <SvgIcon svg={Image} />
}

export default FupaIcon;