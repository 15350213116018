import * as React from "react";
import {ChildrenProps} from "../../../framework-candidates/propTypes";
import Team from "../../../components/Team/Team";
import logo from "../../../imgs/youth/2022_23/b_jugend.jpg";
import logoMin from "../../../imgs/youth/2022_23/b_jugend_min.jpg";

type TeamProps = {};
type AllProps = ChildrenProps;

const TeamBPage: React.FC<AllProps> = props => {
  const config = Config.b;
  return <>
    <Team
      title={"B-Jugend"}
      trainer={config.trainer}
      spielklasse={config.spielklasse}
      trainingstermine={config.trainingstermine}
      logo={logo}
      logoMin={logoMin}
      kader={{}}
    />
    {/*{<TeamResults tabs={*/}
    {/*  [ { tabDescription: "Tabelle", widgetKey: config.fussballDe.tabelle },*/}
    {/*    { tabDescription: "Ergebnisse", widgetKey: config.fussballDe.ergebnisse }]*/}
    {/*} />}*/}
  </>
}

export default TeamBPage;