import * as React from "react";
import { Carousel } from "antd";
import Overlay from "../framework-candidates/common/Overlay";
import { carouselImages } from "../imgs"; // carouselImages, 
import CarouselOverlay from "../components/CarouselOverlay";
import frontpage from "../imgs/frontpage.jpg"
import FussballDeWidget from "../components/Team/FussballDeWidget";
import _ from "lodash";

const HomePage: React.FC<{}> = () => {
  const [imageReady, setImageReady] = React.useState(false);
  const vereinsspielplanConfig = Config.fussballDe.vereinsspielplan;
  const onImageLoad = () => setImageReady(true);
  const startImage = (<div key={-1}><img src={frontpage} alt="" onLoad={onImageLoad} /></div>);
  const [images, setImages] = React.useState([startImage]);

  React.useEffect(() => {
    if(imageReady && images.length === 1) {
      const shuffledImages = _.shuffle(carouselImages);
      setImages([
        startImage,
        ...Object.values(shuffledImages).map((img: any, i) => <div key={i}><img src={img} alt="" /></div>)
      ])
    }
  }, [imageReady, images.length, startImage])

  return (
    <>
      <Overlay foreground={<CarouselOverlay />} display={() => imageReady}>
        <Carousel autoplay autoplaySpeed={5000}>
          {images}
        </Carousel>
      </Overlay>

      {imageReady && <FussballDeWidget widgetKey={vereinsspielplanConfig} />}
    </>
  )
}

export default HomePage