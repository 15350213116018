import Sponsor from "./Sponsor";
import SponsorType from "./SponsorType";
import logoJunited from "../../imgs/sponsors/silver/junited_AUTOGLAS_HomePage.png";
import logoTaxiBercan from "../../imgs/sponsors/silver/Taxi_Bercan_HomePage.jpg";
import logoHBGebaeudereinigung from "../../imgs/sponsors/silver/HB Gebäudereinigung Stadionbanner JPEG-Datei.jpg";
import logoYamYam from "../../imgs/sponsors/silver/yam-yam-logo-1601363700.png";
import logoPetrenko from "../../imgs/sponsors/silver/Petrenko Reinigung HomePage.jpg";
import logoPizzaDoenerTime from "../../imgs/sponsors/silver/Pizza Döner Time final.jpg.png";
import logoLeppertSchlosserei from "../../imgs/sponsors/silver/Leppert Schlosserei.png";
import logoUllmann from "../../imgs/sponsors/silver/Ullmann_GmbH.jpeg";
import logoKraus from "../../imgs/sponsors/silver/kraus.png";
import logoAral from "../../imgs/sponsors/silver/aral-logo.png"
import logoHair from "../../imgs/sponsors/silver/hair_angel.png"
import logoFuss from "../../imgs/sponsors/silver/fachfußpflege.jpg"
import logoYamas from "../../imgs/sponsors/silver/yamas.png"
import logoTiff from "../../imgs/sponsors/silver/tiff.jpg"
import logoTipico from "../../imgs/sponsors/silver/tipico.jpg"
import logoMisterFix from "../../imgs/sponsors/silver/mister_fix.png"
import logoMueller from "../../imgs/sponsors/silver/müller_design.jpg"
import logoSchuller from "../../imgs/sponsors/silver/Logo-Partyservice-Schuller.png"
import logoGebhardt from "../../imgs/sponsors/silver/elektro_gebhardt.png"
import logoLido from "../../imgs/sponsors/silver/Lido-Citta-Logo.png"
import logoCasa from "../../imgs/sponsors/silver/casa.png"


const silverSponsors: Sponsor[] = [
  {
    Name: "junited AUTOGLAS Rastatt",
    Homepage: "https://junited-rastatt.de/",
    SponsorType: SponsorType.Silver,
    Img: logoJunited
  },
  {Name: "Taxi Bercan", Homepage: "https://www.taxi-bercan.de/", SponsorType: SponsorType.Silver, Img: logoTaxiBercan},
  {
    Name: "Innengestaltung Kraus",
    Homepage: "https://innengestaltung-kraus.de/",
    SponsorType: SponsorType.Silver,
    Img: logoKraus
  },
  {
    Name: "Bistro Tiffany‘s",
    Homepage: "https://www.facebook.com/people/Bistro-Tiffanys/100054236151744/",
    SponsorType: SponsorType.Silver,
    Img: logoTiff
  },
  {
    Name: "Tipico Sportwetten Sinzheim",
    Homepage: "https://www.tipico.de/wett-tipps/stores/stadt/sinzheim",
    SponsorType: SponsorType.Silver,
    Img: logoTipico
  },
  {
    Name: "HB Gebäudereinigung",
    Homepage: "https://reinigungsfirma-buehl.de/",
    SponsorType: SponsorType.Silver,
    Img: logoHBGebaeudereinigung
  },
  {
    Name: "Yam Yam China & Thai Food",
    Homepage: "https://www.yam-yam.asia/",
    SponsorType: SponsorType.Silver,
    Img: logoYamYam
  },
  {Name: "Petrenko Reinigung MS", Homepage: undefined, SponsorType: SponsorType.Silver, Img: logoPetrenko},
  {Name: "Müller Design", Homepage: undefined, SponsorType: SponsorType.Silver, Img: logoMueller},
  {Name: "Pizza Döner Time", Homepage: undefined, SponsorType: SponsorType.Silver, Img: logoPizzaDoenerTime},
  {
    Name: "Leppert GmbH",
    Homepage: "http://www.leppert-gmbh.de/",
    SponsorType: SponsorType.Silver,
    Img: logoLeppertSchlosserei
  },
  {Name: "Ullmann GmbH", Homepage: "https://www.ullmann-gmbh.com/", SponsorType: SponsorType.Silver, Img: logoUllmann},
  {
    Name: "Aral Tankstelle",
    Homepage: "https://tankstelle.aral.de/huegelsheim/hauptstrasse-1/18068600",
    SponsorType: SponsorType.Other,
    Img: logoAral
  },
  {
    Name: "Friseursalon Hair Angel Hügelsheim",
    Homepage: "https://www.facebook.com/people/Friseursalon-Hair-Angel-H%C3%BCgelsheim/100057517836228/?ref=py_c",
    SponsorType: SponsorType.Other,
    Img: logoHair
  },
  {
    Name: "Fachfußpflege und mehr",
    Homepage: "https://www.facebook.com/people/Fu%C3%9Fpflege-und-mehr/100071768803839/",
    SponsorType: SponsorType.Other,
    Img: logoFuss
  },
  {
    Name: "Yamas Hügelsheim",
    Homepage: "https://www.yamas-huegelsheim.de/",
    SponsorType: SponsorType.Other,
    Img: logoYamas
  },
  {Name: "Mister Fix", Homepage: undefined, SponsorType: SponsorType.Other, Img: logoMisterFix},
  {
    Name: "Partyservice Schuller",
    Homepage: "https://www.facebook.com/people/partyservice_schuller/100054373532971/",
    SponsorType: SponsorType.Other,
    Img: logoSchuller
  },{
    Name: "Gebhardt Elektro GmbH",
    Homepage: "https://www.gebhard-elektro.de/",
    SponsorType: SponsorType.Silver,
    Img: logoGebhardt
  },{
    Name: "MTM GmbH Lido Città",
    Homepage: "https://lido-citta.de/",
    SponsorType: SponsorType.Silver,
    Img: logoLido
  },{
    Name: "Casa Daniele",
    Homepage: "https://www.casa-daniele.de/",
    SponsorType: SponsorType.Silver,
    Img: logoCasa
  }

];

export default silverSponsors;