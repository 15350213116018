import Sponsor from "./Sponsor";
import SponsorType from "./SponsorType";
import logoMixMarkt from "../../imgs/sponsors/platin/MIX_Markt.png";
import logoEdeka from "../../imgs/sponsors/platin/edeka_oser.png";
import logoAundB from "../../imgs/sponsors/platin/AB_Fahrschule_Logo_Mobil.png";

const platinSponsors: Sponsor[] = [
  {
    Name: "Mix Markt Rastatt",
    Homepage: "https://www.mixmarkt.eu/de/germany/maerkte/10/",
    SponsorType: SponsorType.Platin,
    Img: logoMixMarkt
  },
  {
    Name: "EDEKA Oser",
    Homepage: "https://www.edeka.de/eh/s%C3%BCdwest/edeka-oser-am-hecklehamm-2/index.jsp",
    SponsorType: SponsorType.Platin,
    Img: logoEdeka
  },
  {
    Name: "A&B Fahrschule GmbH",
    Homepage: "https://www.fahrschule-ab.de/",
    SponsorType: SponsorType.Platin,
    Img: logoAundB
  },

];

export default platinSponsors;